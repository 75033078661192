import './App.css';
import {useEffect, useState} from "react";

import {CognitoUserPool} from 'amazon-cognito-identity-js';

import {Route, Switch} from "react-router-dom";

import {StringParam, useQueryParam} from 'use-query-params';

import Signin from "./Signin";

import Axios from 'axios';

import * as uuid from 'uuid';
import 'moment/locale/ko'
import moment from 'moment';

import AWS from 'aws-sdk';

moment.locale('ko');

const DEFAULT_ENTRY = {
    필드명: "",
    규격: "",
    가격: "",
    업체명: "",
    기타: "",
};

const ENTRY_PLACEHOLDER = {
    필드명: "각관100*100",
    규격: "m2",
    가격: "23000",
    업체명: "무한철물",
    기타: "상품질",
}

function ImageResource({id}) {
    const s3 = new AWS.S3();

    const [url, setUrl] = useState();

    useEffect(() => {
        s3.getSignedUrlPromise('getObject', {
            Bucket: process.env.REACT_APP_RESOURCES_BUCKET_NAME,
            Key: id,
        }).then(setUrl);
    }, [id]);

    return (
        <img src={url} style={{"maxWidth": "50px", "maxHeight": "50px"}} alt={""}/>
    );
}

async function uploadImage(input) {
    return new Promise((resolve, reject) => {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const s3 = new AWS.S3();

                const id = uuid.v4();

                await s3.upload({
                    Bucket: process.env.REACT_APP_RESOURCES_BUCKET_NAME,
                    Key: id,
                    Body: e.target.result,
                    ContentType: 'image',
                }).promise();

                resolve(id);
            };

            reader.readAsArrayBuffer(input.files[0]);
        }
    })
}

function App() {
    const [list, setList] = useState();
    const [history, setHistory] = useState();
    const [loginChecked, setLoginChecked] = useState(); // TODO: Context 기반으로 수정
    const [session, setSession] = useState(); // TODO: Context 기반으로 수정
    const [updateList, setUpdateList] = useState();

    const [currentId, setCurrentId] = useQueryParam('id', StringParam);

    const [entry, setEntry] = useState(DEFAULT_ENTRY);

    function EntryInput({field}) {
        return (
            <input key={field} type={"text"} value={entry[field]}
                   style={{"width": "100%", "padding": "0", "margin": "0", "border": "0"}}
                   placeholder={ENTRY_PLACEHOLDER[field]}
                   onChange={(e) => {
                       setEntry({
                           ...entry,
                           [field]: e.target.value,
                       });
                   }}
            />
        )
    }

    const axios = Axios.create({
        headers: {
            'Authorization': session && `Bearer ${session.getIdToken().jwtToken}`,
        },
    }); // TODO: Context 기반으로 수정

    async function postEntry(entry) {
        await axios.post('/api/post', entry);

        setUpdateList(true);
    }

    async function deleteEntry(id) {
        await axios.post('/api/delete', {id});

        setUpdateList(true);
    }

    useEffect(() => {
        if (list && currentId) {
            const item = list.find(x => x.id === currentId);

            if (item) {
                setEntry(item);
            } else {
                setCurrentId(undefined);
            }
        }
    }, [session, list, currentId]);

    useEffect(() => {
        if (currentId) {
            (async () => {
                const r = await axios.post('/api/history', {id: currentId});

                setHistory(r.data.rows);
            })();
        } else {
            setHistory([]);
        }
    }, [session, currentId]);

    useEffect(() => {
        if (updateList) {
            (async () => {
                const r = await axios.post("/api/list", {});

                setList(r.data.rows);
                setUpdateList(false);
            })();
        }
    }, [updateList]);

    useEffect(() => {
        (async () => {
            if (window.location.pathname !== '/signin') {
                const poolData = {
                    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
                    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
                };

                const userPool = new CognitoUserPool(poolData);

                const cognitoUser = userPool.getCurrentUser();

                if (!cognitoUser) {
                    window.location.href = `${process.env.REACT_APP_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=https://${window.location.host}/signin&response_type=code`;
                } else {
                    cognitoUser.getSession((e, session) => {
                        AWS.config.region = 'ap-northeast-2';
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
                            Logins: {
                                [`cognito-idp.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
                                    session.getIdToken().getJwtToken(),
                            },
                        });

                        setSession(session);
                        setLoginChecked(true);
                        setUpdateList(true);
                    });
                }
            } else {
                setLoginChecked(true);
            }
        })();
    }, []);

    return (
        <Switch>
            <Route path={"/signin"}>
                <Signin/>
            </Route>
            <Route path={"/"} exact={true}>
                <div style={{"display": "flex", "flexDirection": "row"}}>
                    <div style={{
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "20vw",
                        "justifyContent": "start",
                        "alignItems": "center",
                    }}>
                        <table border={1} style={{"width": '90%'}}>
                            <tr>
                                <td>필드명</td>
                                <td>{EntryInput({field: "필드명"})}</td>
                            </tr>
                            <tr>
                                <td>규격</td>
                                <td>{EntryInput({field: "규격"})}</td>
                            </tr>
                            <tr>
                                <td>가격</td>
                                <td>{EntryInput({field: "가격"})}</td>
                            </tr>
                            <tr>
                                <td>업체명</td>
                                <td>{EntryInput({field: "업체명"})}</td>
                            </tr>
                            <tr>
                                <td>연락처</td>
                                <td>{EntryInput({field: "연락처"})}</td>
                            </tr>
                            <tr>
                                <td>담당자</td>
                                <td>{EntryInput({field: "담당자"})}</td>
                            </tr>
                            <tr>
                                <td>기타</td>
                                <td>{EntryInput({field: "기타"})}</td>
                            </tr>
                            <tr>
                                <td>이미지</td>
                                <td>
                                    {
                                        entry.이미지 && <ImageResource id={entry.이미지}/>
                                    }
                                    <input type={"file"} accept={"image/*"} onChange={e => {
                                        uploadImage(e.target).then(id => {
                                            setEntry({
                                                ...entry,
                                                이미지: id,
                                            });
                                            e.target.value = '';
                                        });
                                    }}/>
                                </td>
                            </tr>
                        </table>
                        {
                            currentId && (
                                <div style={{"width": '90%', 'display': 'flex', 'justifyContent': 'space-between'}}>
                                    <button style={{"width": "65%"}} onClick={() => {
                                        postEntry(entry).then(() => {
                                            setEntry(DEFAULT_ENTRY);
                                            setCurrentId(undefined);
                                        });
                                    }}>수정
                                    </button>
                                    <button style={{"width": "30%"}} onClick={() => {
                                        setCurrentId(undefined);
                                        setEntry(DEFAULT_ENTRY);
                                    }}>취소
                                    </button>
                                </div>
                            ) || (
                                <button style={{"width": "90%"}} onClick={() => {
                                    postEntry({
                                        ...entry,
                                        id: uuid.v4(),
                                    }).then(() => {
                                        setEntry(DEFAULT_ENTRY);
                                    });
                                }}>등록
                                </button>
                            )
                        }
                    </div>
                    <div style={{
                        "display": "flex",
                        "width": "50vw",
                        "justifyContent": "center",
                        'height': 'fit-content'
                    }}>
                        <table border={1} style={{"width": "95%"}}>
                            <tr>
                                <td>필드명</td>
                                <td>규격</td>
                                <td>가격</td>
                                <td>업체명</td>
                                <td>연락처</td>
                                <td>담당자</td>
                                <td>기타</td>
                                <td>이미지</td>
                                <td>업데이트날짜</td>
                                <td>상세</td>
                                <td>삭제</td>
                            </tr>
                            {
                                list && list.map(x => {
                                    return (
                                        <tr key={x.id}>
                                            <td>{x.필드명}</td>
                                            <td>{x.규격}</td>
                                            <td>{x.가격}</td>
                                            <td>{x.업체명}</td>
                                            <td>{x.연락처}</td>
                                            <td>{x.담당자}</td>
                                            <td>{x.기타}</td>
                                            <td>{x.이미지 && <ImageResource id={x.이미지}/>}</td>
                                            <td>{moment(x.updated_at).format("LLL")}</td>
                                            <td>
                                                <button
                                                    style={{
                                                        "width": "100%",
                                                        "height": "100%",
                                                    }}
                                                    onClick={() => {
                                                        setCurrentId(x.id);
                                                    }}>상세
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    style={{
                                                        "width": "100%",
                                                        "height": "100%",
                                                    }}
                                                    onClick={() => {
                                                        deleteEntry(x.id);
                                                    }}>삭제
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </table>
                    </div>
                    <div style={{
                        "display": "flex",
                        "width": "30vw",
                        "justifyContent": "center",
                        'height': 'fit-content'
                    }}>
                        <table border={1} style={{"width": '95%'}}>
                            <tr>
                                <td>필드명</td>
                                <td>규격</td>
                                <td>가격</td>
                                <td>업체명</td>
                                <td>연락처</td>
                                <td>담당자</td>
                                <td>기타</td>
                                <td>이미지</td>
                                <td>업데이트 날짜</td>
                            </tr>
                            {
                                history && history.map(x => {
                                    return (
                                        <tr key={x.id}>
                                            <td>{x.필드명}</td>
                                            <td>{x.규격}</td>
                                            <td>{x.가격}</td>
                                            <td>{x.업체명}</td>
                                            <td>{x.연락처}</td>
                                            <td>{x.담당자}</td>
                                            <td>{x.기타}</td>
                                            <td>{x.이미지 && <ImageResource id={x.이미지}/>}</td>
                                            <td>{moment(x.timestamp).format("LLL")}</td>
                                        </tr>
                                    );
                                })
                            }
                        </table>
                    </div>
                </div>
            </Route>
        </Switch>
    );
}

export default App;
